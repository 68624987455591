<template>
  <div class="row">
    <b-container fluid>
      <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: center; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ QUA ỨNG DỤNG/WEBSITE {{appName.toUpperCase()}}</span></strong></strong></p>
      <p><span style="color: rgb(0, 0, 0);"><span style="font-family: 'Times New Roman', Times, serif;"><strong>ĐIỀU KHOẢN SỬ DỤNG DỊCH VỤ QUA ỨNG DỤNG/WEBSITE {{appName.toUpperCase()}}</strong> (sau đây gọi tắt là “<strong>Điều Khoản Sử Dụng Dịch Vụ</strong>") này quy định các quyền và nghĩa vụ của Người Sử Dụng, với tư cách là Khách Hàng, khi sử dụng Dịch Vụ do TỔNG CÔNG TY DỊCH VỤ VIỄN THÔNG cung cấp trên Ứng Dụng/Website {{appName}} . </span> </span></p>
      <p><span style='font-family: "Times New Roman", Times, serif; font-size: 16px; color: rgb(0, 0, 0);'>Bên cạnh Điều Khoản Sử Dụng Dịch Vụ này, VNPT VinaPhone ban hành kèm theo Chính sách bảo vệ dữ liệu cá nhân của khách hàng. Chính sách bảo vệ dữ liệu cá nhân của Khách Hàng là một phần không tách rời của Điều Khoản Sử Dụng Dịch Vụ này, Khách Hàng cần đọc, hiểu và chấp thuận Điều Khoản Sử Dụng Dịch Vụ và Chính sách bảo vệ dữ liệu cá nhân của Khách Hàng trước khi đăng ký, sử dụng Dịch Vụ qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 1: Giải thích từ ngữ</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';"></span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">Ứng Dụng/Website {{appName}}&rdquo;</strong></strong>&nbsp;<strong><strong style="box-sizing: border-box;"></strong></strong>&nbsp;l&agrave; ứng dụng/website kết nối phụ huynh v&agrave; nh&agrave; trường nhằm hỗ trợ trao đổi th&ocirc;ng tin hai chiều giữa nh&agrave; trường v&agrave; phụ huynh học sinh, bao gồm, nhưng kh&ocirc;ng giới hạn bởi, th&ocirc;ng tin về kết quả học tập, thời kh&oacute;a biểu, th&ocirc;ng b&aacute;o nghỉ học, th&ocirc;ng b&aacute;o họp phụ huynh. Ngo&agrave;i ra, ứng dụng/website c&ograve;n t&iacute;ch hợp c&aacute;c t&iacute;nh năng n&acirc;ng cao g&oacute;p phần chuyển đối số ng&agrave;nh Gi&aacute;o dục như thanh to&aacute;n học ph&iacute;, tuyển sinh đầu cấp, giao b&agrave;i tập về nh&agrave;, t&igrave;nh trạng sức khỏe v.v.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';"></span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">T&agrave;i Khoản Ứng Dụng/Website {{appName}}</strong></strong>&nbsp;<strong><strong style="box-sizing: border-box;"></strong></strong>&rdquo; l&agrave; t&agrave;i khoản điện tử tr&ecirc;n hệ thống c&ocirc;ng nghệ th&ocirc;ng tin của VNPT VinaPhone do VNPT VinaPhone gửi cho Kh&aacute;ch H&agrave;ng để truy cập, sử dụng Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.3.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">Dịch Vụ</strong></strong>&rdquo; l&agrave; c&aacute;c dịch vụ được cung cấp qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.4.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">Kh&aacute;ch H&agrave;ng</strong></strong>&rdquo; l&agrave; người sử dụng thực hiện cung cấp th&ocirc;ng tin, đăng k&yacute; v&agrave; sử dụng c&aacute;c Dịch Vụ được cung cấp qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.6.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">Phiếu Đề Nghị Cung Cấp Dịch Vụ Qua Ứng Dụng/Website {{appName}}</strong></strong>&rdquo; l&agrave; văn bản đề nghị cung cấp Dịch Vụ được k&yacute; kết giữa Kh&aacute;ch H&agrave;ng v&agrave; VNPT VinaPhone theo mẫu do VNPT VinaPhone ban h&agrave;nh.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.7.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;<strong><strong style="box-sizing: border-box;">Ng&agrave;y L&agrave;m Việc</strong></strong>&rdquo;&nbsp;</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">l&agrave; một ng&agrave;y kh&ocirc;ng phải Thứ Bảy, Chủ Nhật hoặc ng&agrave;y lễ m&agrave; c&aacute;c ng&acirc;n h&agrave;ng mở cửa kinh doanh b&igrave;nh thường tại Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">1.8.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&ldquo;</span><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Ph&aacute;p Luật Việt Nam</span></strong></strong><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&rdquo; l&agrave; bất kỳ hiệp ước, quy chế, bộ luật/luật, lệnh, nghị quyết, ph&aacute;p lệnh, nghị định, chỉ thị, quyết định, th&ocirc;ng tư, quy định, c&ocirc;ng văn hoặc bất kỳ diễn giải n&agrave;o đối với c&aacute;c văn bản quy phạm ph&aacute;p luật vừa n&ecirc;u được ban h&agrave;nh, ph&aacute;t h&agrave;nh hoặc c&ocirc;ng bố bởi bất kỳ Cơ quan Chức năng n&agrave;o của Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 0px 0px 10px; text-align: justify;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 17.12px; font-family: 'Times New Roman', serif; color: black;">Điều 2:&nbsp;</span></strong></strong><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 13pt; line-height: 18.5467px; font-family: 'Times New Roman', serif; color: black">Phạm vi cung cấp v&agrave; sử dụng dịch vụ</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">VNPT VinaPhone cung cấp cho Kh&aacute;ch H&agrave;ng dịch vụ để kết nối nh&agrave; trường v&agrave; phụ huynh học sinh th&ocirc;ng qua Ứng Dụng/Website {{appName}} theo c&aacute;c điều khoản v&agrave; điều kiện quy định tại Điều Khoản Sử Dụng Dịch Vụ n&agrave;y.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 3: Thanh to&aacute;n cước ph&iacute; Dịch Vụ</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">3.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Chu kỳ thanh to&aacute;n: Việc thanh to&aacute;n cước ph&iacute; Dịch Vụ thực hiện theo chu kỳ g&oacute;i cước v&agrave; &aacute;p dụng h&igrave;nh thức trả trước. Kh&aacute;ch H&agrave;ng thanh to&aacute;n cho VNPT VinaPhone trong thời hạn 07 ng&agrave;y kể từ ng&agrave;y nhận được th&ocirc;ng b&aacute;o cước ph&iacute; Dịch Vụ của VNPT VinaPhone. Sau khi nhận đủ cước ph&iacute; Dịch Vụ, VNPT VinaPhone sẽ xuất h&oacute;a đơn cho Kh&aacute;ch H&agrave;ng. H&oacute;a đơn cước ph&iacute; Dịch Vụ do VNPT VinaPhone cung cấp sẽ được coi như Kh&aacute;ch H&agrave;ng chấp thuận nếu Kh&aacute;ch H&agrave;ng kh&ocirc;ng c&oacute; khiếu nại v&agrave; đưa ra l&yacute; do khiếu nại ch&iacute;nh đ&aacute;ng, trong thời hạn 05 ng&agrave;y kể từ ng&agrave;y nhận được h&oacute;a đơn cước ph&iacute; Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">3.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&aacute;ch H&agrave;ng kh&ocirc;ng được ho&agrave;n trả cước ph&iacute; Dịch Vụ đ&atilde; thanh to&aacute;n cho VNPT VinaPhone trong mọi trường hợp. Gi&aacute; trị thanh to&aacute;n c&oacute; thể b&ugrave; trừ với khoản c&ocirc;ng nợ kh&aacute;c giữa VNPT VinaPhone v&agrave; Kh&aacute;ch H&agrave;ng (nếu c&oacute;).</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">3.3.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&aacute;ch H&agrave;ng thanh to&aacute;n cước ph&iacute; Dịch Vụ cho VNPT VinaPhone bằng tiền mặt, v&iacute; VNPT Money hoặc chuyển khoản bằng tiền Việt Nam đồng.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 4: Quyền v&agrave; nghĩa vụ của Kh&aacute;ch H&agrave;ng</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">4.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Quyền của Kh&aacute;ch H&agrave;ng:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Được sử dụng Dịch Vụ theo đ&uacute;ng quy định tại Điều Khoản Sử Dụng Dịch Vụ, Ch&iacute;nh s&aacute;ch bảo vệ dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch H&agrave;ng v&agrave; Ph&aacute;p Luật Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Y&ecirc;u cầu VNPT VinaPhone cung cấp th&ocirc;ng tin li&ecirc;n quan đến việc sử dụng Dịch Vụ v&agrave; dịch vụ gi&aacute; trị gia tăng k&egrave;m theo (nếu c&oacute;).</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Được quyền y&ecirc;u cầu VNPT VinaPhone bảo mật c&aacute;c th&ocirc;ng tin m&agrave; Kh&aacute;ch H&agrave;ng đ&atilde; cung cấp cho</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">&nbsp;VNPT VinaPhone theo quy định của Ph&aacute;p Luật Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">4.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Nghĩa vụ của Kh&aacute;ch H&agrave;ng:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Thanh to&aacute;n đầy đủ, đ&uacute;ng hạn cước ph&iacute; Dịch Vụ v&agrave; chịu chi ph&iacute; (chuyển tiền, ph&iacute; ng&acirc;n h&agrave;ng v&agrave; c&aacute;c chi ph&iacute; kh&aacute;c c&oacute; li&ecirc;n quan) ph&aacute;t sinh khi thanh to&aacute;n cước ph&iacute; Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Cung cấp đầy đủ, ch&iacute;nh x&aacute;c v&agrave; kịp thời to&agrave;n bộ c&aacute;c th&ocirc;ng tin theo y&ecirc;u cầu củaVNPT VinaPhone.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Đảm bảo Kh&aacute;ch H&agrave;ng c&oacute; đầy đủ tư c&aacute;ch, thẩm quyền đăng k&yacute;, sử dụng Dịch Vụ v&agrave; thực hiện c&aacute;c quyền v&agrave; nghĩa vụ quy định tại Điều Khoản Sử Dụng Dịch Vụ n&agrave;y (bao gồm cả việc Kh&aacute;ch H&agrave;ng c&oacute; đầy đủ chấp thuận từ học sinh m&agrave; Kh&aacute;ch H&agrave;ng l&agrave; phụ huynh hoặc cho ph&eacute;p VNPT VinaPhone thực hiện xử l&yacute; dữ liệu c&aacute; nh&acirc;n của học sinh).</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(d)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&ocirc;ng được chuyển giao quyền v&agrave; nghĩa vụ theo Điều Khoản Sử Dụng Dịch Vụ n&agrave;y cho b&ecirc;n thứ ba trước khi được sự đồng &yacute; của VNPT VinaPhone. Kh&ocirc;ng cho ph&eacute;p bất kỳ c&aacute; nh&acirc;n, tổ chức kh&aacute;c sử dụng Dịch Vụ. Kh&ocirc;ng được sử dụng Dịch Vụ để thực hiện h&agrave;nh vi vi phạm ph&aacute;p luật.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(e)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Khi c&oacute; sự cố Dịch Vụ, Kh&aacute;ch H&agrave;ng phải th&ocirc;ng b&aacute;o cho đầu mối li&ecirc;n hệ của VNPT VinaPhone để c&ugrave;ng phối hợp xử l&yacute;.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 5: Quyền v&agrave; nghĩa vụ của VNPT VinaPhone</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">5.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Quyền của VNPT VinaPhone:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Y&ecirc;u cầu Kh&aacute;ch H&agrave;ng thanh to&aacute;n cước ph&iacute; Dịch Vụ theo đ&uacute;ng g&oacute;i cước Kh&aacute;ch H&agrave;ng đ&atilde; đăng k&yacute; theo Phiếu Đề Nghị Cung Cấp Dịch Vụ Qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Y&ecirc;u cầu Kh&aacute;ch H&agrave;ng cung cấp th&ocirc;ng tin cần thiết li&ecirc;n quan đến việc cung cấp Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Gi&aacute;m s&aacute;t việc sử dụng Dịch Vụ của Kh&aacute;ch H&agrave;ng theo quy định của Ph&aacute;p Luật Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(d)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Được loại trừ tr&aacute;ch nhiệm với Kh&aacute;ch H&agrave;ng v&agrave; b&ecirc;n thứ ba trong trường hợp Kh&aacute;ch H&agrave;ng bị lộ/mất mật khẩu T&agrave;i Khoản Ứng Dụng/Website {{appName}} hoặc mất/thất lạc thiết bị di động dẫn đến người kh&ocirc;ng c&oacute; thẩm quyền tự &yacute; sử dụng T&agrave;i Khoản Ứng Dụng/Website {{appName}} hoặc hệ thống, mạng m&aacute;y t&iacute;nh, mạng viễn th&ocirc;ng v&agrave; c&aacute;c phương tiện điện tử bị x&acirc;m nhập tr&aacute;i ph&eacute;p bởi b&ecirc;n thứ ba mặc d&ugrave; VNPT VinaPhone đ&atilde; thực hiện đầy đủ c&aacute;c biện ph&aacute;p để bảo vệ hệ thống.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(e)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&ocirc;ng chịu tr&aacute;ch nhiệm về thiệt hại của Kh&aacute;ch H&agrave;ng, c&aacute;c khiếu nại, khởi kiện Kh&aacute;ch H&agrave;ng từ c&aacute;c b&ecirc;n kh&aacute;c nếu kh&ocirc;ng xuất ph&aacute;t từ lỗi trực tiếp của VNPT VinaPhone.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(f)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&ocirc;ng chịu tr&aacute;ch nhiệm về nội dung, t&iacute;nh ch&iacute;nh x&aacute;c, t&iacute;nh hợp l&yacute; v&agrave; t&iacute;nh kịp thời đối với c&aacute;c th&ocirc;ng tin nh&agrave; trường cung cấp cho Kh&aacute;ch H&agrave;ng hoặc th&ocirc;ng tin Kh&aacute;ch H&agrave;ng cung cấp cho nh&agrave; trường th&ocirc;ng qua việc sử dụng Dịch Vụ của VNPT VinaPhone; trong đ&oacute;, &ldquo;Nh&agrave; trường&rdquo; trong Điều Khoản Sử Dụng Dịch Vụ n&agrave;y được hiểu l&agrave; Trường c&oacute; t&ecirc;n tại phần th&ocirc;ng tin của Kh&aacute;ch H&agrave;ng tr&ecirc;n Phiếu Đề Nghị Cung Cấp Dịch Vụ Qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">5.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Nghĩa vụ của VNPT VinaPhone:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Đảm bảo chất lượng Dịch Vụ theo đ&uacute;ng chất lượng đ&atilde; c&ocirc;ng bố.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Trong khả năng của m&igrave;nh, tu&acirc;n thủ nghi&ecirc;m ngặt c&aacute;c quy định về xử l&yacute; dữ liệu c&aacute; nh&acirc;n, xử l&yacute; dữ liệu c&aacute; nh&acirc;n của trẻ em theo quy định của Điều Khoản Sử Dụng Dịch Vụ n&agrave;y, theo Ch&iacute;nh s&aacute;ch bảo vệ dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch H&agrave;ng tại từng thời điểm v&agrave; theo quy định của Ph&aacute;p Luật Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Hướng dẫn Kh&aacute;ch H&agrave;ng sử dụng Dịch Vụ v&agrave; phối hợp triển khai Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(d)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Phối hợp với Kh&aacute;ch H&agrave;ng trong qu&aacute; tr&igrave;nh vận h&agrave;nh, khai th&aacute;c, bảo dưỡng v&agrave; xử l&yacute; sự cố trong hệ thống.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(e)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Ngay khi nhận được th&ocirc;ng b&aacute;o sự cố từ Kh&aacute;ch H&agrave;ng, VNPT VinaPhone t&igrave;m hiểu, x&aacute;c định nguy&ecirc;n nh&acirc;n v&agrave; thời gian xảy ra sự cố để khắc phục trong thời gian sớm nhất.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(f)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Th&ocirc;ng b&aacute;o ngay cho Kh&aacute;ch H&agrave;ng trong trường hợp nh&agrave; nước hoặc cơ quan nh&agrave; nước c&oacute; thẩm quyền ngừng cho ph&eacute;p cung cấp Dịch Vụ qua Ứng Dụng/Website {{appName}}. Trường hợp n&agrave;y, VNPT VinaPhone sẽ được loại trừ to&agrave;n bộ tr&aacute;ch nhiệm bồi thường thiệt hại v&agrave;/hoặc phạt vi phạm hợp đồng v&igrave; việc ngừng cung cấp Dịch Vụ kh&ocirc;ng phải do lỗi của VNPT VinaPhone.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(g)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Giải quyết khiếu nại của Kh&aacute;ch H&agrave;ng theo quy định của VNPT VinaPhone, Tập đo&agrave;n Bưu ch&iacute;nh Viễn th&ocirc;ng Việt Nam, Bộ Th&ocirc;ng tin v&agrave; Truyền th&ocirc;ng v&agrave; tu&acirc;n thủ quy định của Ph&aacute;p Luật Việt Nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 6: Thời hạn sử dụng, chấm dứt Dịch Vụ trước thời hạn</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">6.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Thời hạn sử dụng Dịch Vụ theo thời hạn của g&oacute;i Dịch Vụ m&agrave; Kh&aacute;ch H&agrave;ng đ&atilde; đăng k&yacute;, trừ trường hợp c&oacute; thỏa thuận kh&aacute;c. Dịch Vụ c&oacute; thể gia hạn theo thỏa thuận của c&aacute;c b&ecirc;n. Khi hết thời hạn sử dụng Dịch Vụ m&agrave; Kh&aacute;ch H&agrave;ng kh&ocirc;ng c&oacute; y&ecirc;u cầu gia hạn th&igrave; VNPT VinaPhone thực hiện chấm dứt việc cung cấp Dịch Vụ cho Kh&aacute;ch H&agrave;ng.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">6.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Việc cung cấp&nbsp;</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Dịch Vụ chấm dứt trước thời hạn trong những trường hợp sau:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">C&aacute;c b&ecirc;n thỏa thuận chấm dứt Dịch Vụ trước thời hạn.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Khi c&oacute; y&ecirc;u cầu của nh&agrave; trường hoặc cơ quan nh&agrave; nước c&oacute; thẩm quyền ngừng cho ph&eacute;p cung cấp Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Do Kh&aacute;ch H&agrave;ng c&oacute; h&agrave;nh vi vi phạm ph&aacute;p luật, hoặc vi phạm Điều Khoản Sử Dụng Dịch vụ hoặc kh&ocirc;ng thanh to&aacute;n cước ph&iacute; Dịch Vụ.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 7: Khiếu nại v&agrave; giải quyết tranh chấp</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">7.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Kh&aacute;ch H&agrave;ng c&oacute; quyền khiếu nại VNPT VinaPhone về cước ph&iacute; Dịch Vụ, chất lượng Dịch Vụ hoặc c&aacute;c nội dung kh&aacute;c theo quy định tại Ch&iacute;nh s&aacute;ch bảo vệ dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch H&agrave;ng. VNPT VinaPhone c&oacute; tr&aacute;ch nhiệm giải quyết khiếu nại của Kh&aacute;ch H&agrave;ng theo quy định của VNPT VinaPhone, Tập đo&agrave;n Bưu Ch&iacute;nh Viễn th&ocirc;ng Việt Nam, Bộ Th&ocirc;ng tin v&agrave; Truyền th&ocirc;ng v&agrave; tu&acirc;n thủ quy định của Ph&aacute;p Luật Việt nam.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">7.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều Khoản Sử Dụng Dịch Vụ n&agrave;y được giải th&iacute;ch, hướng dẫn v&agrave; điều chỉnh bởi Ph&aacute;p Luật Việt Nam. Mọi tranh chấp ph&aacute;t sinh từ Điều Khoản Sử Dụng Dịch Vụ n&agrave;y được ưu ti&ecirc;n giải quyết th&ocirc;ng qua thương lượng, ho&agrave; giải. Trong trường hợp c&aacute;c b&ecirc;n kh&ocirc;ng thể thương lượng, h&ograve;a giải trong v&ograve;ng 30 ng&agrave;y kể từ ng&agrave;y tranh chấp ph&aacute;t sinh th&igrave; tranh chấp sẽ được giải quyết tại To&agrave; &aacute;n nh&acirc;n d&acirc;n c&oacute; thẩm quyền nơi VNPT VinaPhone đặt trụ sở ch&iacute;nh. Ph&aacute;n quyết của To&agrave; &aacute;n l&agrave; cuối c&ugrave;ng v&agrave; c&aacute;c b&ecirc;n phải c&oacute; nghĩa vụ thi h&agrave;nh. Mọi chi ph&iacute; ph&aacute;t sinh b&ecirc;n thua kiện chịu.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 8: Th&ocirc;ng b&aacute;o</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">8.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">VNPT VinaPhone c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o cho Kh&aacute;ch H&agrave;ng về đầu mối c&oacute; thẩm quyền li&ecirc;n hệ c&ocirc;ng việc li&ecirc;n quan đến Điều Khoản Sử Dụng Dịch Vụ v&agrave;/hoặc Phiếu Đề Nghị Cung Cấp Dịch Vụ Qua Ứng Dụng/Website {{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">8.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Mọi th&ocirc;ng b&aacute;o, y&ecirc;u cầu, đề nghị hoặc h&igrave;nh thức truyền đạt th&ocirc;ng tin kh&aacute;c của một b&ecirc;n li&ecirc;n quan đến Điều Khoản Sử Dụng Dịch Vụ v&agrave;/hoặc Phiếu Đề Nghị Cung Cấp Dịch Vụ Qua Ứng Dụng/Website {{appName}} &nbsp;(&ldquo;Th&ocirc;ng b&aacute;o&rdquo;) chỉ c&oacute; gi&aacute; trị ph&aacute;p l&yacute; với b&ecirc;n c&ograve;n lại khi thể hiện bằng văn bản v&agrave; chuyển cho b&ecirc;n c&ograve;n lại theo một trong c&aacute;c h&igrave;nh thức: văn bản trao tay/thư bảo đảm (văn bản giấy), fax hoặc h&igrave;nh thức điện tử kh&aacute;c (email/zalo/viber/skype/&hellip;) của người c&oacute; thẩm quyền. B&ecirc;n th&ocirc;ng b&aacute;o theo h&igrave;nh thức điện tử phải cung cấp văn bản giấy cho B&ecirc;n nhận th&ocirc;ng b&aacute;o ngay sau đ&oacute; để x&aacute;c thực th&ocirc;ng tin.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">8.3.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Trường hợp c&aacute;c b&ecirc;n gửi nhiều th&ocirc;ng b&aacute;o, th&ocirc;ng b&aacute;o cuối c&ugrave;ng theo thời gian sẽ được &aacute;p dụng. Nếu th&ocirc;ng b&aacute;o nhận được c&ugrave;ng thời gian sẽ &aacute;p dụng theo thứ tự ưu ti&ecirc;n: h&igrave;nh thức điện tử, fax, văn bản trao tay/thư bảo đảm.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">8.4.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Mỗi b&ecirc;n sẽ th&ocirc;ng b&aacute;o cho b&ecirc;n kia về bất kỳ thay đổi n&agrave;o đối với đầu mối li&ecirc;n hệ, địa chỉ, số điện thoại, email, h&igrave;nh thức li&ecirc;n hệ điện tử kh&aacute;c của m&igrave;nh. VNPT VinaPhone được loại trừ mọi tr&aacute;ch nhiệm trong trường hợp kh&ocirc;ng thể th&ocirc;ng b&aacute;o, li&ecirc;n lạc với Kh&aacute;ch H&agrave;ng.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 9: Trường hợp Bất khả kh&aacute;ng</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">9.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Sự kiện bất khả kh&aacute;ng l&agrave; sự kiện xảy ra một c&aacute;ch kh&aacute;ch quan kh&ocirc;ng thể lường trước được v&agrave; kh&ocirc;ng thể khắc phục được mặc d&ugrave; đ&atilde; &aacute;p dụng mọi biện ph&aacute;p cần thiết v&agrave; khả năng cho ph&eacute;p, bao gồm nhưng kh&ocirc;ng giới hạn c&aacute;c sự kiện sau: chiến tranh, bạo loạn, đ&igrave;nh c&ocirc;ng, hỏa hoạn, b&atilde;o, lũ lụt, động đất, s&oacute;ng thần, dịch bệnh, c&aacute;ch ly kiểm dịch, sự thay đổi trong ch&iacute;nh s&aacute;ch, ph&aacute;p luật v&agrave;/hoặc y&ecirc;u cầu của cơ quan nh&agrave; nước c&oacute; thẩm quyền dẫn đến việc một b&ecirc;n hoặc c&aacute;c b&ecirc;n kh&ocirc;ng thực hiện được đầy đủ c&aacute;c quyền v&agrave; nghĩa vụ quy định tại Điều Khoản Sử Dụng Dịch Vụ n&agrave;y.&nbsp;</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">9.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Một b&ecirc;n kh&ocirc;ng thực hiện đ&uacute;ng nghĩa vụ do sự kiện bất khả kh&aacute;ng sẽ được loại trừ tr&aacute;ch nhiệm d&acirc;n sự v&agrave; kh&ocirc;ng phải l&agrave; cơ sở để b&ecirc;n c&ograve;n lại chấm dứt Điều khoản sử dụng dịch vụ n&agrave;y hoặc &aacute;p dụng chế t&agrave;i phạt vi phạm, bồi thường thiệt hại. Tuy nhi&ecirc;n b&ecirc;n bị ảnh hưởng bởi sự kiện bất khả kh&aacute;ng c&oacute; nghĩa vụ phải:</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(a)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Tiến h&agrave;nh c&aacute;c biện ph&aacute;p ngăn ngừa hợp l&yacute; v&agrave; c&aacute;c biện ph&aacute;p thay thế cần thiết để hạn chế tối đa ảnh hưởng của sự kiện bất khả kh&aacute;ng g&acirc;y ra.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(b)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Th&ocirc;ng b&aacute;o cho ph&iacute;a b&ecirc;n c&ograve;n lại về sự kiện bất khả kh&aacute;ng ngay sau khi xảy ra sự kiện bất khả kh&aacute;ng. T&ugrave;y từng trường hợp cụ thể, thu thập chứng cứ v&agrave;/hoặc giấy x&aacute;c nhận/th&ocirc;ng b&aacute;o của cơ quan, tổ chức c&oacute; thẩm quyền tại nơi xảy ra sự kiện bất khả kh&aacute;ng để cung cấp cho b&ecirc;n c&ograve;n lại.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(c)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Thực hiện c&aacute;c nghĩa vụ kh&ocirc;ng bị ảnh hưởng bởi sự kiện bất khả kh&aacute;ng.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 63pt; text-align: justify; text-indent: -27pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">(d)<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Tiếp tục thực hiện c&aacute;c nghĩa vụ bị tạm ngừng ảnh hưởng bởi sự kiện bất khả kh&aacute;ng ngay sau khi t&aacute;c động của sự kiện bất khả kh&aacute;ng chấm dứt.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">9.3.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">C&aacute;c b&ecirc;n c&oacute; thể thoả thuận k&eacute;o d&agrave;i thời gian cung cấp Dịch vụ, nếu việc thực hiện Điều Khoản Sử Dụng Dịch Vụ n&agrave;y bị chậm trễ do sự kiện bất khả kh&aacute;ng. Nếu sự kiện bất khả kh&aacute;ng k&eacute;o d&agrave;i hoặc g&acirc;y hậu quả nghi&ecirc;m trọng dẫn đến việc tiếp tục thực hiện Điều Khoản Sử Dụng Dịch Vụ n&agrave;y sẽ kh&ocirc;ng c&oacute; lợi cho c&aacute;c b&ecirc;n th&igrave; c&aacute;c b&ecirc;n c&oacute; thể thỏa thuận chấm dứt việc thực hiện Điều Khoản Sử Dụng Dịch Vụ n&agrave;y.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm; text-align: justify; line-height: 16.1px;"><strong><strong style="box-sizing: border-box;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều 10: Điều khoản chung</span></strong></strong></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">10.1.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Điều Khoản Sử Dụng Dịch Vụ n&agrave;y được đăng tải tr&ecirc;n Ứng Dụng/Website&nbsp;</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">{{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">10.2.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Tại từng thời điểm, tuy theo y&ecirc;u cầu của hoạt động sản xuất kinh doanh, VNPT VinaPhone c&oacute; thể sửa đổi, bổ sung, thay thế Điều Khoản Sử Dụng Dịch Vụ n&agrave;y v&agrave; thực hiện cập nhập Điều Khoản Sử Dụng Dịch Vụ mới tr&ecirc;n&nbsp;</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">Ứng dụng&nbsp;</span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">{{appName}}.</span></p>
          <p style="box-sizing: border-box; margin: 12pt 0cm 12pt 36pt; text-align: justify; text-indent: -36pt; line-height: 16.1px;"><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">10.3.<span style="box-sizing: border-box; font: 7pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style="box-sizing: border-box; font-size: 12pt; line-height: 18.4px; font-family: 'Times New Roman', serif; color: black;">VNPT VinaPhone v&agrave; Kh&aacute;ch H&agrave;ng cam kết tu&acirc;n thủ đầy đủ Điều Khoản Sử Dụng Dịch Vụ n&agrave;y v&agrave; những sửa đổi/bổ sung (nếu c&oacute;) tại từng thời điểm trong qu&aacute; tr&igrave;nh cung cấp v&agrave; sử dụng dịch vụ.</span></p>

    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'

export default {
  name: 'TermsOfUse',
  components: {
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>
<style scoped lang="scss">

</style>
