<template>
  <div class="row">
    <b-container fluid>
      <p
        lang="EN-US"
        style="text-align: center;"
      >
        <span style="color: #000000; font-size: 12pt;"><strong><span style="font-family: 'times new roman', times;">CH&Iacute;NH S&Aacute;CH BẢO VỆ DỮ LIỆU C&Aacute; NH&Acirc;N ĐỐI VỚI KH&Aacute;CH H&Agrave;NG CỦA VNPT V&Agrave; C&Ocirc;NG TY CON CỦA VNPT</span></strong></span>
      </p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Ch&iacute;nh s&aacute;ch bảo vệ dữ liệu c&aacute; nh&acirc;n n&agrave;y ("<strong>Ch&iacute;nh s&aacute;ch</strong>") nhằm mục đ&iacute;ch th&ocirc;ng b&aacute;o với Kh&aacute;ch h&agrave;ng những Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng do Tập đo&agrave;n Bưu ch&iacute;nh Viễn th&ocirc;ng Việt Nam (<strong>VNPT</strong>) v&agrave;/hoặc do c&aacute;c C&ocirc;ng ty con c&oacute; 100% vốn điều lệ của VNPT ("<strong>C&ocirc;ng ty con của VNPT</strong>") xử l&yacute;, mục đ&iacute;ch xử l&yacute;, c&aacute;ch thức xử l&yacute;, thời gian lưu trữ, quyền, nghĩa vụ của Kh&aacute;ch h&agrave;ng đối với Dữ liệu c&aacute; nh&acirc;n của m&igrave;nh theo quy định của ph&aacute;p luật Việt Nam về bảo vệ Dữ liệu c&aacute; nh&acirc;n. Ch&iacute;nh s&aacute;ch n&agrave;y đồng thời đưa ra c&aacute;c khuyến nghị để gi&uacute;p Kh&aacute;ch h&agrave;ng n&acirc;ng cao nhận thức về bảo vệ Dữ liệu c&aacute; nh&acirc;n.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Ch&iacute;nh s&aacute;ch n&agrave;y l&agrave; một phần kh&ocirc;ng thể t&aacute;ch rời của c&aacute;c bản Hợp đồng. Điều khoản chung, Điều khoản sử dụng sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT. Ch&iacute;nh s&aacute;ch n&agrave;y được &aacute;p dụng cho to&agrave;n bộ hoạt động cung cấp sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT v&agrave; &aacute;p dụng tr&ecirc;n to&agrave;n bộ c&aacute;c nền tảng c&oacute; tương t&aacute;c với Kh&aacute;ch h&agrave;ng c&aacute; nh&acirc;n tr&ecirc;n m&ocirc;i trường số.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 1. Giải th&iacute;ch từ ngữ v&agrave; c&aacute;c từ viết tắt</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Trong phạm vi Ch&iacute;nh s&aacute;ch n&agrave;y, c&aacute;c thuật ngữ dưới đ&acirc;y được hiểu v&agrave; giải th&iacute;ch như sau:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1.1.&nbsp;<strong>VNPT</strong>&nbsp;l&agrave; Tập Đo&agrave;n Bưu Ch&iacute;nh Viễn Th&ocirc;ng Việt Nam v&agrave; c&aacute;c đơn vị trực thuộc Tập Đo&agrave;n Bưu Ch&iacute;nh Viễn Th&ocirc;ng Việt Nam.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1.2.&nbsp;<strong>C&ocirc;ng ty con của VNPT</strong>&nbsp;l&agrave; c&aacute;c doanh nghiệp do VNPT sở hữu 100% vốn điều lệ. C&ocirc;ng ty con của VNPT bao gồm T&ocirc;ng c&ocirc;ng ty Dịch vụ Viễn th&ocirc;ng (VNPT VinaPhone) v&agrave; Tổng c&ocirc;ng ty Truyền th&ocirc;ng (VNPT Media).</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1.3.&nbsp;<strong>Kh&aacute;ch h&agrave;ng</strong>&nbsp;l&agrave;:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">- C&aacute; nh&acirc;n hoặc người đại diện hợp ph&aacute;p của c&aacute; nh&acirc;n sử dụng v&agrave; hoặc quan t&acirc;m tới c&aacute;c sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">- C&aacute; nh&acirc;n hoặc người đại diện hợp ph&aacute;p của c&aacute; nh&acirc;n đ&atilde; truy cập v&agrave; hoặc đăng k&yacute; t&agrave;i khoản tại c&aacute;c website/ứng dụng thuộc quyền sở hữu của VNPT/C&ocirc;ng ty con của VNPT</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1.4.&nbsp;<strong>Sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT</strong>&nbsp;l&agrave;:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">- Sản phẩm, dịch vụ do VNPT hoặc c&aacute;c C&ocirc;ng ty con của VNPT trực tiếp nghi&ecirc;n cứu, ph&aacute;t triển v&agrave; cung cấp cho Kh&aacute;ch h&agrave;ng,</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">- Sản phẩm, dịch vụ do VNPT C&ocirc;ng ty con của VNPT hợp t&aacute;c với đối t&aacute;c để cung cấp cho Kh&aacute;ch h&agrave;ng.</span></p>
      <h4 class="indent ">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 2. Xử l&yacute; Dữ liệu c&aacute; nh&acirc;n</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. VNPT C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o Ch&iacute;nh s&aacute;ch n&agrave;y cho Kh&aacute;ch h&agrave;ng v&agrave; lấy chấp thuận của Kh&aacute;ch h&agrave;ng trước khi tiến h&agrave;nh xử l&yacute; Dữ liệu c&aacute; nh&acirc;n trong những trường hợp dưới đ&acirc;y:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Khi Kh&aacute;ch h&agrave;ng hoặc người đại diện hợp ph&aacute;p của Kh&aacute;ch h&agrave;ng li&ecirc;n hệ với VNPT C&ocirc;ng ty con của VNPT để y&ecirc;u cầu tư vấn sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT hoặc b&agrave;y tỏ sự quan t&acirc;m tới c&aacute;c sản phẩm, dịch vụ của VNPT/C&ocirc;ng ty con của VNPT:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Khi Kh&aacute;ch h&agrave;ng d&ugrave;ng thử, k&yacute; kết hợp đồng, đăng k&yacute;, sử dụng sản phẩm, dịch vụ của VNPT C&ocirc;ng ty con của VNPT;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">c) Khi Kh&aacute;ch h&agrave;ng truy cập v&agrave; hoặc đăng k&yacute; t&agrave;i khoản tại c&aacute;c website/&ocirc;ng dụng sản phẩm, dịch vụ của VNPT/C&ocirc;ng ty con của VNPT;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">d) Khi Kh&aacute;ch h&agrave;ng đồng &yacute; cung cấp Dữ liệu c&aacute; nh&acirc;n cho VNPT C&ocirc;ng ty con của VNPT qua c&aacute;c nguồn c&ocirc;ng khai như: website ứng dụng sản phẩm, dịch vụ của VNPT/C&ocirc;ng ty con của VNPT, cuộc họp, sự kiện, hội thảo, hội nghị, c&aacute;c mạng x&atilde; hội, hay chương tr&igrave;nh đổi thoại, thảo luận do VNPT C&ocirc;ng ty con của VNPT tổ chức, t&agrave;i trợ hoặc tham dự v&agrave; hoặc từ c&aacute;c tệp lưu trữ (cookies) ghi nhận được tr&ecirc;n website của VNPT/C&ocirc;ng ty con của VNPT</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">e) Khi kh&aacute;ch h&agrave;ng của một tổ chức, doanh nghiệp cho ph&eacute;p tổ chức, doanh nghiệp đ&oacute; chia sẽ dữ liệu c&aacute; nh&acirc;n của kh&aacute;ch h&agrave;ng với VNPT C&ocirc;ng ty con của VNPT;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">g) L&agrave; kh&aacute;ch h&agrave;ng của một tổ chức, doanh nghiệp dược VNPT C&ocirc;ng ty con của VNPT thực hiện g&oacute;p vốn, mua cổ phần: hoặc l&agrave; kh&aacute;ch h&agrave;ng của một tổ chức, doanh nghiệp c&oacute; hoạt động hợp t&aacute;c cung cấp sản phẩm, dịch vụ với VNPT/C&ocirc;ng ty con của VNPT.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">h) Khi c&oacute; y&ecirc;u cầu của c&aacute;c cơ quan nh&agrave; nước c&oacute; thẩm quyền.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">i) Khi VNPT C&ocirc;ng ty con của VNPT tiến h&agrave;nh c&aacute;c c&ocirc;ng việc theo mục đ&iacute;ch xử l&yacute; Dữ liệu c&aacute; nh&acirc;n được quy định tại Điều 3 Ch&iacute;nh s&aacute;ch n&agrave;y.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">j) C&aacute;c trường hợp kh&aacute;c theo quy định của ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng được VNPT/C&ocirc;ng ty con của VNPT tiến h&agrave;nh xử l&yacute; (sau đ&acirc;y gọi tắt l&agrave; &ldquo;<strong>Dữ liệu c&aacute; nh&acirc;n</strong>&rdquo;) bao gồm những th&ocirc;ng tin dưới đ&acirc;y v&agrave; c&oacute; thể thay đổi t&ugrave;y thuộc v&agrave;o loại sản phẩm hoặc dịch vụ, c&aacute;ch thức tương t&aacute;c của Kh&aacute;ch h&agrave;ng với VNPT/C&ocirc;ng ty con của VNPT (<em>VNPT/C&ocirc;ng ty con của VNPT sẽ th&ocirc;ng b&aacute;o cụ thể c&aacute;c thay đổi (nếu c&oacute;) v&agrave; lấy lại sự đồng &yacute; của chủ thể dữ liệu đối với c&aacute;c nội dung thay đổi đ&oacute;</em>):</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng được VNPT C&ocirc;ng ty con của VNPT tiến h&agrave;nh xử l&yacute; (sau đ&acirc;y gọi tắt l&agrave; &ldquo;Dữ liệu c&aacute; nh&acirc;n") bao gồm những th&ocirc;ng tin dưới đ&acirc;y v&agrave; c&oacute; thể thay đổi t&ugrave;y thuộc v&agrave;o loại sản phẩm hoặc dịch vụ, c&aacute;ch thức tương t&aacute;c của Kh&aacute;ch h&agrave;ng với VNPT C&ocirc;ng ty con của VNPT (VNPT C&ocirc;ng ty con của VNPT sẽ thống hảo cụ thể c&aacute;c thay đổi (nếu c&oacute;) v&agrave; lấy lại sự đồng &yacute; của chủ thể đ&atilde; liệu đối với c&aacute;c nội dung thay đổi đ&oacute;).</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2.1. Dữ liệu c&aacute; nh&acirc;n cơ bản</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Họ, chứ đệm v&agrave; t&ecirc;n khai sinh, t&ecirc;n gọi kh&aacute;c (nếu c&oacute;);</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Ng&agrave;y, th&aacute;ng, năm sinh; ng&agrave;y, th&aacute;ng, năm chết hoặc mất t&iacute;ch;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">c) Giới t&iacute;nh;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">d) Nơi sinh, nơi đăng k&yacute; khai sinh, nơi thường tr&uacute;, nơi tạm tr&uacute;, nơi ở hiện tại, qu&ecirc; qu&aacute;n, địa chỉ li&ecirc;n hệ;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">đ) Quốc tịch;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">e) H&igrave;nh ảnh của c&aacute; nh&acirc;n;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">g) Số điện thoại, số chứng minh nh&acirc;n d&acirc;n, số định danh c&aacute; nh&acirc;n, số hộ chiếu, số giấy ph&eacute;p l&aacute;i xe, số biển số xe, số m&atilde; số thuế c&aacute; nh&acirc;n, số bảo hiểm x&atilde; hội, số thẻ bảo hiểm y tế;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">h) T&igrave;nh trạng h&ocirc;n nh&acirc;n;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">i) Th&ocirc;ng tin về mối quan hệ gia đ&igrave;nh (cha mẹ, con c&aacute;i);</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">k) Th&ocirc;ng tin về t&agrave;i khoản số của c&aacute; nh&acirc;n; dữ liệu c&aacute; nh&acirc;n phản &aacute;nh hoạt động, lịch sử hoạt động tr&ecirc;n kh&ocirc;ng gian mạng;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">l) C&aacute;c th&ocirc;ng tin kh&aacute;c gắn liền với một con người cụ thể hoặc gi&uacute;p x&aacute;c định một con người cụ thể kh&ocirc;ng thuộc quy định tại khoản 2.2 Điều n&agrave;y.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2.2. Dữ liệu c&aacute; nh&acirc;n nhạy cảm</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Dữ liệu về tội phạm, h&agrave;nh vi phạm tội được thu thập, lưu trữ bởi c&aacute;c cơ quan thực thi ph&aacute;p luật;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Th&ocirc;ng tin Kh&aacute;ch h&agrave;ng của tổ chức t&iacute;n dụng, chi nh&aacute;nh ng&acirc;n h&agrave;ng nước ngo&agrave;i, tổ chức cung ứng dịch vụ trung gian thanh to&aacute;n, c&aacute;c tổ chức được ph&eacute;p kh&aacute;c, gồm: th&ocirc;ng tin định danh Kh&aacute;ch h&agrave;ng theo quy định của ph&aacute;p luật, th&ocirc;ng tin về t&agrave;i khoản, th&ocirc;ng tin về tiền gửi, th&ocirc;ng tin về t&agrave;i sản gửi, th&ocirc;ng tin về giao dịch, th&ocirc;ng tin về tổ chức, c&aacute; nh&acirc;n l&agrave; b&ecirc;n bảo đảm tại tổ chức t&iacute;n dụng, chi nh&aacute;nh ng&acirc;n h&agrave;ng, tổ chức cung ứng dịch vụ trung gian thanh to&aacute;n;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">c) Dữ liệu về vị tr&iacute; của c&aacute; nh&acirc;n được x&aacute;c định qua dịch vụ định vị.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">d) Dữ liệu c&aacute; nh&acirc;n kh&aacute;c được ph&aacute;p luật quy định l&agrave; đặc th&ugrave; v&agrave; cần c&oacute; biện ph&aacute;p bảo mật cần thiết.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2.4. VNPT/C&ocirc;ng ty con của VNPT sẽ th&ocirc;ng b&aacute;o cho Kh&aacute;ch h&agrave;ng c&aacute;c Dữ liệu c&aacute; nh&acirc;n bắt buộc phải cung cấp v&agrave;/hoặc t&ugrave;y chọn cung cấp tại thời điểm Kh&aacute;ch h&agrave;ng li&ecirc;n hệ, trao đổi hoặc đăng k&yacute;, k&yacute; kết hợp đồng với VNPT/C&ocirc;ng ty con của VNPT. Dữ liệu c&aacute; nh&acirc;n bắt buộc phải cung cấp được hiểu l&agrave; c&aacute;c Dữ liệu c&aacute; nh&acirc;n m&agrave; VNPT/C&ocirc;ng ty con của VNPT cần phải thu thập từ Kh&aacute;ch h&agrave;ng theo quy định của ph&aacute;p luật hoặc c&aacute;c Dữ liệu c&aacute; nh&acirc;n thiết yếu để phục vụ việc triển khai cung cấp một phần hoặc to&agrave;n bộ sản phẩm, dịch vụ của VNPT/C&ocirc;ng ty con của VNPT cho Kh&aacute;ch h&agrave;ng.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Nếu c&aacute;c Dữ liệu c&aacute; nh&acirc;n bắt buộc kh&ocirc;ng được cung cấp theo y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT, Kh&aacute;ch h&agrave;ng sẽ kh&ocirc;ng thể sử dụng một số sản phẩm, dịch vụ của VNPT/C&ocirc;ng ty con của VNPT. Trong trường hợp n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể từ chối cung cấp sản phẩm, dịch vụ cho Kh&aacute;ch h&agrave;ng m&agrave; kh&ocirc;ng phải chịu bất k&igrave; một khoản bồi thường v&agrave;/hoặc phạt vi phạm n&agrave;o (trừ trường hợp do lỗi của VNPT/C&ocirc;ng ty con của VNPT).</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2.5. Tại từng thời điểm, Kh&aacute;ch h&agrave;ng c&oacute; thể tự nguyện cung cấp cho VNPT/C&ocirc;ng ty con của VNPT c&aacute;c Dữ liệu c&aacute; nh&acirc;n nằm ngo&agrave;i y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT. Khi Kh&aacute;ch h&agrave;ng cung cấp Dữ liệu c&aacute; nh&acirc;n nằm ngo&agrave;i y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT đồng nghĩa với việc Kh&aacute;ch h&agrave;ng cho ph&eacute;p VNPT/C&ocirc;ng ty con của VNPT xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng với Mục Đ&iacute;ch được n&ecirc;u trong Ch&iacute;nh s&aacute;ch n&agrave;y hoặc với mục đ&iacute;ch được n&ecirc;u tại thời điểm Kh&aacute;ch h&agrave;ng cung cấp những Dữ liệu c&aacute; nh&acirc;n đ&oacute;. Ngo&agrave;i ra, khi Kh&aacute;ch h&agrave;ng chủ động cung cấp th&ocirc;ng tin nằm ngo&agrave;i y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT, Kh&aacute;ch h&agrave;ng vui l&ograve;ng kh&ocirc;ng cung cấp Dữ liệu c&aacute; nh&acirc;n nhạy cảm theo quy định của ph&aacute;p luật tại từng thời điểm. VNPT/C&ocirc;ng ty con của VNPT sẽ kh&ocirc;ng thực hiện xử l&yacute; v&agrave; kh&ocirc;ng chịu bất k&igrave; tr&aacute;ch nhiệm ph&aacute;p l&yacute; n&agrave;o đối với c&aacute;c Dữ liệu c&aacute; nh&acirc;n nhạy cảm do Kh&aacute;ch h&agrave;ng tự nguyện cung cấp nằm ngo&agrave;i y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 3. Mục đ&iacute;ch xử l&yacute; Dữ liệu c&aacute; nh&acirc;n</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Trừ trường hợp quy định tại Điều 13 Ch&iacute;nh s&aacute;ch n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT phải thực hiện th&ocirc;ng b&aacute;o v&agrave; lấy chấp thuận của Kh&aacute;ch h&agrave;ng trước khi xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng. Dữ liệu c&aacute; nh&acirc;n thu thập, cập nhật, bổ sung phải ph&ugrave; hợp v&agrave; giới hạn trong phạm vi, mục đ&iacute;ch cần xử l&yacute; theo quy định tại Ch&iacute;nh s&aacute;ch n&agrave;y. Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng chỉ được xử l&yacute; cho một hoặc một số mục đ&iacute;ch được Kh&aacute;ch h&agrave;ng đồng thuận sau đ&acirc;y (&ldquo;<strong>Mục Đ&iacute;ch</strong>&rdquo;)&nbsp;chi tiết tại (*)</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 4. C&aacute;ch thức xử l&yacute; Dữ liệu c&aacute; nh&acirc;n</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">VNPT/C&ocirc;ng ty con của VNPT &aacute;p dụng một hoặc nhiều hoạt động t&aacute;c động tới Dữ liệu c&aacute; nh&acirc;n như: thu thập, ghi, ph&acirc;n t&iacute;ch, x&aacute;c nhận, lưu trữ, chỉnh sửa, c&ocirc;ng khai, kết hợp, truy cập, truy xuất, thu hồi, m&atilde; h&oacute;a, giải m&atilde;, sao ch&eacute;p, chia sẻ, truyền đưa, cung cấp, chuyển giao, x&oacute;a, hủy Dữ liệu c&aacute; nh&acirc;n hoặc c&aacute;c h&agrave;nh động kh&aacute;c c&oacute; li&ecirc;n quan ph&ugrave; hợp với quy định của ph&aacute;p luật.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 5. Thời gian bắt đầu, thời gian kết th&uacute;c xử l&yacute; dữ liệu</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Thời gian bắt đầu xử l&yacute; dữ liệu</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kể từ thời điểm ph&aacute;t sinh c&aacute;c Mục Đ&iacute;ch quy định tại Điều 3 Ch&iacute;nh s&aacute;ch n&agrave;y.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Thời gian kết th&uacute;c xử l&yacute; dữ liệu</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">VNPT/C&ocirc;ng ty con của VNPT chấm dứt việc xử l&yacute; Dữ liệu c&aacute; nh&acirc;n khi đ&atilde; ho&agrave;n th&agrave;nh Mục Đ&iacute;ch quy định tại Ch&iacute;nh s&aacute;ch n&agrave;y, trừ trường hợp ph&aacute;p luật c&oacute; quy định kh&aacute;c hoặc Kh&aacute;ch h&agrave;ng r&uacute;t lại sự đồng &yacute; việc xử l&yacute; Dữ liệu c&aacute; nh&acirc;n hoặc khi cơ quan nh&agrave; nước c&oacute; thẩm quyền y&ecirc;u cầu bằng văn bản.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 6. Chia sẻ Dữ liệu c&aacute; nh&acirc;n</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Trừ trường hợp quy định tại Điều 13 Ch&iacute;nh s&aacute;ch n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o v&agrave; phải lấy sự chấp thuận của Kh&aacute;ch h&agrave;ng khi chia sẻ Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng cho c&aacute;c tổ chức, c&aacute; nh&acirc;n dưới đ&acirc;y để thực hiện c&aacute;c Mục Đ&iacute;ch quy định tại Ch&iacute;nh s&aacute;ch, cụ thể:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. VNPT, C&ocirc;ng ty con của VNPT, c&ocirc;ng ty li&ecirc;n kết của VNPT, c&ocirc;ng ty li&ecirc;n kết của C&ocirc;ng ty con của VNPT; 2. B&ecirc;n thứ ba cung cấp dịch vụ hoặc c&aacute;c đối t&aacute;c trong c&aacute;c hợp đồng hợp t&aacute;c kinh doanh (c&oacute; ph&acirc;n chia lợi nhuận hoặc kh&ocirc;ng ph&acirc;n chia lợi nhuận): VNPT/C&ocirc;ng ty con của VNPT sử dụng v&agrave;/hoặc hợp t&aacute;c với c&aacute;c c&ocirc;ng ty v&agrave; c&aacute; nh&acirc;n kh&aacute;c để thực hiện một số c&ocirc;ng việc v&agrave; chương tr&igrave;nh như chương tr&igrave;nh quảng c&aacute;o, khuyến mại d&agrave;nh cho Kh&aacute;ch h&agrave;ng, nghi&ecirc;n cứu thị trường, ph&acirc;n t&iacute;ch v&agrave; ph&aacute;t triển sản phẩm, tư vấn chiến lược, cung cấp dịch vụ thu cước. C&aacute;c B&ecirc;n thứ ba cung cấp dịch vụ v&agrave;/hoặc c&aacute;c đối t&aacute;c n&agrave;y c&oacute; quyền truy cập, thu thập, sử dụng v&agrave; xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng trong phạm vi VNPT/C&ocirc;ng ty con của VNPT cho ph&eacute;p để thực hiện c&aacute;c chức năng của họ v&agrave; phải tu&acirc;n thủ quy định của ph&aacute;p luật về bảo vệ Dữ liệu c&aacute; nh&acirc;n với tư c&aacute;ch l&agrave; B&ecirc;n Xử l&yacute; Dữ Liệu;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. T&aacute;i cấu tr&uacute;c doanh nghiệp: Trong qu&aacute; tr&igrave;nh ph&aacute;t triển kinh doanh, VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể b&aacute;n hoặc mua c&aacute;c doanh nghiệp hoặc t&aacute;i cấu tr&uacute;c doanh nghiệp ph&ugrave; hợp với quy định của ph&aacute;p luật v&agrave; nhu cầu sản xuất kinh doanh. Trong c&aacute;c giao dịch như vậy, Dữ liệu c&aacute; nh&acirc;n sẽ được chuyển nhượng v&agrave; b&ecirc;n nhận chuyển nhượng vẫn phải tu&acirc;n theo c&aacute;c quy định của Ch&iacute;nh s&aacute;ch n&agrave;y;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">4. VNPT/C&ocirc;ng ty con của VNPT được ph&eacute;p tiết lộ Dữ liệu c&aacute; nh&acirc;n theo y&ecirc;u cầu của ph&aacute;p luật, y&ecirc;u cầu của cơ quan quản l&yacute; nh&agrave; nước c&oacute; thẩm quyền;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">5. VNPT/C&ocirc;ng ty con của VNPT được ph&eacute;p tiết lộ Dữ liệu c&aacute; nh&acirc;n cho c&aacute;c doanh nghiệp viễn th&ocirc;ng kh&aacute;c để phục vụ cho việc t&iacute;nh gi&aacute; cước, lập ho&aacute; đơn v&agrave; ngăn chặn h&agrave;nh vi trốn tr&aacute;nh thực hiện nghĩa vụ theo hợp đồng của Kh&aacute;ch h&agrave;ng.</span></p>
      <p>&nbsp;</p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 7. Quyền của Kh&aacute;ch h&agrave;ng</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Quyền được biết v&agrave; Quyền đồng &yacute;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền được biết về hoạt động xử l&yacute; dữ liệu c&aacute; nh&acirc;n của m&igrave;nh, trừ trường hợp luật c&oacute; quy định kh&aacute;c. Trừ trường hợp quy định tại Điều 13 Ch&iacute;nh s&aacute;ch n&agrave;y, Kh&aacute;ch h&agrave;ng c&oacute; quyền đồng &yacute; hoặc kh&ocirc;ng đồng &yacute; với c&aacute;c điều khoản v&agrave; điều kiện của Ch&iacute;nh s&aacute;ch n&agrave;y theo c&aacute;ch thức đ&atilde; được VNPT/C&ocirc;ng ty con của VNPT hướng dẫn tr&ecirc;n c&aacute;c k&ecirc;nh, phương tiện như tin nhắn SMS, cuộc gọi, dấu t&iacute;ch tr&ecirc;n website/ứng dụng hoặc li&ecirc;n hệ với tổng đ&agrave;i chăm s&oacute;c kh&aacute;ch h&agrave;ng của VNPT/C&ocirc;ng ty con của VNPT ph&ugrave; hợp với quy định của ph&aacute;p luật. VNPT/C&ocirc;ng ty con của VNPT chỉ thực hiện xử l&yacute; Dữ liệu c&aacute; nh&acirc;n khi đ&atilde; c&oacute; chấp thuận của Kh&aacute;ch h&agrave;ng.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Quyền truy cập v&agrave; y&ecirc;u cầu cung cấp Dữ liệu c&aacute; nh&acirc;n Kh&aacute;ch h&agrave;ng c&oacute; quyền truy cập v&agrave;o c&aacute;c ứng dụng/website của VNPT/C&ocirc;ng ty con của VNPT v&agrave;/hoặc li&ecirc;n hệ trực tiếp với VNPT/C&ocirc;ng ty con của VNPT để xem, chỉnh sửa, tr&iacute;ch xuất c&aacute;c Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng, trừ trường hợp ph&aacute;p luật c&oacute; quy định kh&aacute;c. Trường hợp Kh&aacute;ch h&agrave;ng kh&ocirc;ng thể tự truy cập để xem, chỉnh sửa, tr&iacute;ch xuất hoặc gặp kh&oacute; khăn trong việc truy cập để xem, chỉnh sửa hoặc tr&iacute;ch xuất c&aacute;c Dữ liệu c&aacute; nh&acirc;n, Kh&aacute;ch h&agrave;ng vui l&ograve;ng li&ecirc;n hệ với VNPT/C&ocirc;ng ty con của VNPT để được hỗ trợ.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Quyền chỉnh sửa</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền chỉnh sửa c&aacute;c Dữ liệu c&aacute; nh&acirc;n của m&igrave;nh với điều kiện việc chỉnh sửa n&agrave;y kh&ocirc;ng vi phạm c&aacute;c quy định của ph&aacute;p luật. Trường hợp Kh&aacute;ch h&agrave;ng kh&ocirc;ng thể tự chỉnh sửa hoặc gặp kh&oacute; khăn trong việc chỉnh sửa c&aacute;c Dữ liệu c&aacute; nh&acirc;n, Kh&aacute;ch h&agrave;ng c&oacute; thể li&ecirc;n hệ với VNPT/C&ocirc;ng ty con của VNPT để được hỗ trợ.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">4. Quyền phản đối, hạn chế, r&uacute;t lại sự đồng &yacute; xử l&yacute; dữ liệu</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Kh&aacute;ch h&agrave;ng c&oacute; quyền phản đối, y&ecirc;u cầu hạn chế xử l&yacute; Dữ liệu c&aacute; nh&acirc;n hoặc r&uacute;t lại sự đồng &yacute; xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng. Tuy nhi&ecirc;n, việc phản đối, hạn chế hoặc r&uacute;t lại sự đồng &yacute; xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng c&oacute; thể dẫn tới việc VNPT/C&ocirc;ng ty con của VNPT kh&ocirc;ng thể cung cấp Sản phẩm, dịch vụ cho Kh&aacute;ch h&agrave;ng, điều n&agrave;y đồng nghĩa với việc VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể đơn phương chấm dứt hợp đồng m&agrave; kh&ocirc;ng cần phải bồi thường cho Kh&aacute;ch h&agrave;ng do c&aacute;c điều kiện để thực hiện hợp đồng đ&atilde; thay đổi (trừ trường hợp do lỗi của VNPT/C&ocirc;ng ty con của VNPT). Do đ&oacute;, VNPT/C&ocirc;ng ty con của VNPT khuyến nghị Kh&aacute;ch h&agrave;ng c&acirc;n nhắc kĩ lưỡng trước khi phản đối, hạn chế hoặc r&uacute;t lại sự đồng &yacute; xử l&yacute; Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Trường hợp Kh&aacute;ch h&agrave;ng muốn hạn chế nhận nội dung tiếp thị quảng c&aacute;o, khuyến mại từ VNPT/C&ocirc;ng ty con của VNPT v&agrave; muốn r&uacute;t lại sự chấp thuận trước đ&oacute; (nếu c&oacute;) v&agrave;/hoặc phản đối việc tiếp tục sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh cho mục đ&iacute;ch quy định tại khoản 5, Điều 3 Ch&iacute;nh s&aacute;ch n&agrave;y, Kh&aacute;ch h&agrave;ng vui l&ograve;ng thực hiện theo hướng dẫn của VNPT/C&ocirc;ng ty con của VNPT tại thời điểm VNPT/C&ocirc;ng ty con của VNPT thu thập Dữ liệu c&aacute; nh&acirc;n hoặc li&ecirc;n hệ với VNPT/C&ocirc;ng ty con của VNPT theo c&aacute;c th&ocirc;ng tin được cung cấp tại Ch&iacute;nh s&aacute;ch n&agrave;y. Nếu Kh&aacute;ch h&agrave;ng kh&ocirc;ng muốn nhận th&ocirc;ng b&aacute;o từ ứng dụng của VNPT/C&ocirc;ng ty con của VNPT, vui l&ograve;ng điều chỉnh c&agrave;i đặt th&ocirc;ng b&aacute;o trong ứng dụng hoặc thiết bị của m&igrave;nh.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">5. Quyền x&oacute;a Dữ liệu c&aacute; nh&acirc;n</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền y&ecirc;u cầu VNPT/C&ocirc;ng ty con của VNPT thực hiện x&oacute;a Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng với điều kiện l&agrave; y&ecirc;u cầu của Kh&aacute;ch h&agrave;ng phải ph&ugrave; hợp với quy định của ph&aacute;p luật. Tuy nhi&ecirc;n, y&ecirc;u cầu x&oacute;a Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng c&oacute; thể dẫn tới việc VNPT/C&ocirc;ng ty con của VNPT kh&ocirc;ng thể cung cấp Sản phẩm, dịch vụ cho Kh&aacute;ch h&agrave;ng, điều n&agrave;y đồng nghĩa với việc VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể đơn phương chấm dứt hợp đồng m&agrave; kh&ocirc;ng cần phải bồi thường cho Kh&aacute;ch h&agrave;ng do c&aacute;c điều kiện để thực hiện hợp đồng đ&atilde; thay đổi (trừ trường hợp do lỗi của VNPT/C&ocirc;ng ty con của VNPT). Do đ&oacute;, VNPT/C&ocirc;ng ty con của VNPT khuyến nghị Kh&aacute;ch h&agrave;ng c&acirc;n nhắc kĩ lưỡng trước khi y&ecirc;u cầu VNPT/C&ocirc;ng ty con của VNPT thực hiện x&oacute;a Dữ liệu c&aacute; nh&acirc;n.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">6. Quyền khiếu nại, tố c&aacute;o, khởi kiện</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền khiếu nại, tố c&aacute;o hoặc khởi kiện theo quy định của ph&aacute;p luật</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">7. Quyền y&ecirc;u cầu bồi thường thiệt hại</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền y&ecirc;u cầu bồi thường thiệt hại theo quy định của ph&aacute;p luật khi xảy ra vi phạm quy định về bảo vệ Dữ liệu c&aacute; nh&acirc;n của m&igrave;nh, trừ trường hợp c&aacute;c b&ecirc;n c&oacute; thỏa thuận kh&aacute;c hoặc luật c&oacute; quy định kh&aacute;c.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">8. Quyền tự bảo vệ</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; quyền tự bảo vệ theo quy định của Bộ luật D&acirc;n sự, luật kh&aacute;c c&oacute; li&ecirc;n quan v&agrave; Nghị định 13/2023/NĐ-CP về bảo vệ Dữ liệu c&aacute; nh&acirc;n (v&agrave; c&aacute;c bản sửa đổi k&egrave;m theo), hoặc y&ecirc;u cầu cơ quan, tổ chức c&oacute; thẩm quyền thực hiện c&aacute;c phương thức bảo vệ quyền d&acirc;n sự theo quy định tại Điều 11 Bộ luật D&acirc;n sự.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 8. Nghĩa vụ của Kh&aacute;ch h&agrave;ng</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng c&oacute; tr&aacute;ch nhiệm bảo vệ Dữ liệu c&aacute; nh&acirc;n của m&igrave;nh như sau:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Chủ động thực hiện c&aacute;c biện ph&aacute;p bảo vệ, quản l&yacute; v&agrave; sử dụng an to&agrave;n t&agrave;i khoản, thiết bị c&ocirc;ng nghệ c&aacute; nh&acirc;n (bao gồm c&aacute;c thiết bị như điện thoại th&ocirc;ng minh, m&aacute;y t&iacute;nh, m&aacute;y t&iacute;nh bảng, laptop) bằng c&aacute;ch đăng xuất t&agrave;i khoản sau khi sử dụng, đặt một mật khẩu mạnh v&agrave; giữ b&iacute; mật th&ocirc;ng tin đăng nhập cũng như mật khẩu của m&igrave;nh. C&aacute;c biện ph&aacute;p bảo vệ v&agrave; quản l&yacute; sử dụng an to&agrave;n t&agrave;i khoản, thiết bị di động n&oacute;i tr&ecirc;n gi&uacute;p ngăn chặn việc truy cập tr&aacute;i ph&eacute;p v&agrave;o t&agrave;i khoản của Kh&aacute;ch h&agrave;ng. VNPT/C&ocirc;ng ty con của VNPT được loại trừ tr&aacute;ch nhiệm với c&aacute;c thiệt hại của Kh&aacute;ch h&agrave;ng trong trường hợp Kh&aacute;ch h&agrave;ng bị lộ/mất, bị đ&aacute;nh cắp mật khẩu, dẫn tới việc bị truy cập tr&aacute;i ph&eacute;p v&agrave;o t&agrave;i khoản, hoặc bất kỳ hoạt động n&agrave;o tr&ecirc;n t&agrave;i khoản của Kh&aacute;ch h&agrave;ng sử dụng tr&ecirc;n thiết bị di động bị mất, thất lạc dẫn đến người kh&ocirc;ng c&oacute; thẩm quyền tự &yacute; sử dụng dịch vụ, hoặc hệ thống của VNPT bị x&acirc;m phạm bất hợp ph&aacute;p bởi b&ecirc;n thứ ba mặc d&ugrave; VNPT đ&atilde; thực hiện đầy đủ c&aacute;c biện ph&aacute;p để bảo vệ hệ thống (trừ trường hợp do lỗi của VNPT/C&ocirc;ng ty con của VNPT);</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Khi đ&atilde; chấp thuận to&agrave;n bộ điều khoản v&agrave; điều kiện của của Ch&iacute;nh s&aacute;ch n&agrave;y, Kh&aacute;ch h&agrave;ng c&oacute; tr&aacute;ch nhiệm cung cấp Dữ liệu c&aacute; nh&acirc;n đầy đủ, ch&iacute;nh x&aacute;c theo y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT v&agrave; c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o cho VNPT/C&ocirc;ng ty con của VNPT ngay khi ph&aacute;t hiện h&agrave;nh vi vi phạm quy định về bảo vệ Dữ liệu c&aacute; nh&acirc;n. Kh&aacute;ch h&agrave;ng c&oacute; thể chủ động cung cấp Dữ liệu c&aacute; nh&acirc;n nằm ngo&agrave;i y&ecirc;u cầu của VNPT/C&ocirc;ng ty con của VNPT với điều kiện Kh&aacute;ch h&agrave;ng phải tu&acirc;n thủ quy định tại khoản 2.5, Điều 2 Ch&iacute;nh s&aacute;ch n&agrave;y;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Kh&aacute;ch h&agrave;ng c&oacute; tr&aacute;ch nhiệm t&ocirc;n trọng Dữ liệu c&aacute; nh&acirc;n của chủ thể kh&aacute;c v&agrave; thực hiện quy định của ph&aacute;p luật về bảo vệ Dữ liệu c&aacute; nh&acirc;n, tham gia ph&ograve;ng, chống c&aacute;c h&agrave;nh vi vi phạm quy định về bảo vệ Dữ liệu c&aacute; nh&acirc;n.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 9. Lưu trữ Dữ liệu c&aacute; nh&acirc;n</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng do VNPT/C&ocirc;ng ty con của VNPT lưu trữ sẽ được bảo mật. VNPT/C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm thực hiện c&aacute;c biện ph&aacute;p bảo vệ Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng theo quy định của ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Địa điểm lưu trữ Dữ liệu c&aacute; nh&acirc;n</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Trong phạm vi ph&aacute;p luật cho ph&eacute;p, VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể lưu trữ Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng tại Việt Nam v&agrave; ở nước ngo&agrave;i, kể cả tr&ecirc;n giải ph&aacute;p lưu trữ điện to&aacute;n đ&aacute;m m&acirc;y. VNPT/C&ocirc;ng ty con của VNPT &aacute;p dụng c&aacute;c ti&ecirc;u chuẩn về bảo mật dữ liệu ph&ugrave; hợp với quy định ph&aacute;p luật hiện h&agrave;nh. Việc chuyển dữ liệu c&aacute; nh&acirc;n ra nước ngo&agrave;i phải ph&ugrave; hợp với quy định tại Điều 25 Nghị định 13/2023/NĐ-CP v&agrave; c&aacute;c quy định của ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Thời gian lưu trữ Dữ liệu c&aacute; nh&acirc;n</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">VNPT/C&ocirc;ng ty con của VNPT chỉ thực hiện lưu trữ Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng để ho&agrave;n th&agrave;nh c&aacute;c Mục Đ&iacute;ch quy định tại Ch&iacute;nh s&aacute;ch n&agrave;y. Tuy nhi&ecirc;n, trường hợp ph&aacute;p luật hiện h&agrave;nh c&oacute; quy định kh&aacute;c về thời hạn lưu trữ Dữ liệu c&aacute; nh&acirc;n, VNPT/C&ocirc;ng ty con của VNPT c&oacute; nghĩa vụ tu&acirc;n thủ quy định của ph&aacute;p luật.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 10. Nghĩa vụ của VNPT/C&ocirc;ng ty con của VNPT</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng được cam kết bảo mật theo quy định của ph&aacute;p luật, Ch&iacute;nh s&aacute;ch bảo vệ Dữ liệu c&aacute; nh&acirc;n của VNPT/C&ocirc;ng ty con của VNPT.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. VNPT/C&ocirc;ng ty con của VNPT nỗ lực đảm bảo Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng được bảo vệ khỏi c&aacute;c h&agrave;nh vi vi phạm quy định về bảo vệ Dữ liệu c&aacute; nh&acirc;n v&agrave; ph&ograve;ng, chống sự mất m&aacute;t, ph&aacute; hủy hoặc thiệt hại do sự cố, sử dụng c&aacute;c biện ph&aacute;p kỹ thuật. VNPT/C&ocirc;ng ty con của VNPT duy tr&igrave; cam kết bảo mật Dữ liệu c&aacute; nh&acirc;n bằng c&aacute;ch &aacute;p dụng những biện ph&aacute;p vật l&yacute;, điện tử v&agrave; quản l&yacute; để bảo vệ Dữ liệu c&aacute; nh&acirc;n, bao gồm:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) C&aacute;c m&aacute;y chủ trang th&ocirc;ng tin điện tử ch&iacute;nh thức của VNPT v&agrave; c&aacute;c hệ thống th&ocirc;ng tin chứa dữ liệu c&aacute; nh&acirc;n của VNPT đều được bảo vệ bởi c&aacute;c biện ph&aacute;p, c&ocirc;ng nghệ bảo mật như tường lửa, m&atilde; h&oacute;a, chống x&acirc;m nhập tr&aacute;i ph&eacute;p; ban h&agrave;nh c&aacute;c biện ph&aacute;p kiểm so&aacute;t về con người, x&acirc;y dựng quy tr&igrave;nh kiểm tra, đ&aacute;nh gi&aacute;, r&agrave; so&aacute;t để ph&ograve;ng tr&aacute;nh c&aacute;c h&agrave;nh vi vi phạm quy định về bảo vệ Dữ liệu c&aacute; nh&acirc;n.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) VNPT sẽ thực hiện tất cả c&aacute;c biện ph&aacute;p cần thiết để đảm bảo rằng Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng được xử l&yacute; đ&uacute;ng với Mục Đ&iacute;ch đ&atilde; th&ocirc;ng b&aacute;o. VNPT/C&ocirc;ng ty con của VNPT sẽ lu&ocirc;n tu&acirc;n thủ những y&ecirc;u cầu của ph&aacute;p luật li&ecirc;n quan đến việc lưu trữ Dữ liệu c&aacute; nh&acirc;n.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Thực hiện c&aacute;c y&ecirc;u cầu của Kh&aacute;ch h&agrave;ng li&ecirc;n quan đến dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng với điều kiện c&aacute;c y&ecirc;u cầu của Kh&aacute;ch h&agrave;ng phải ph&ugrave; hợp với quy định của ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">4. C&aacute;c nghĩa vụ kh&aacute;c theo quy định của ph&aacute;p luật v&agrave; của Ch&iacute;nh s&aacute;ch n&agrave;y.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 11. Hậu quả, thiệt hại kh&ocirc;ng mong muốn c&oacute; khả năng xảy ra</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. VNPT/C&ocirc;ng ty con của VNPT sử dụng nhiều biện ph&aacute;p, c&ocirc;ng nghệ bảo mật th&ocirc;ng tin kh&aacute;c nhau nhằm bảo vệ Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng kh&ocirc;ng bị sử dụng hoặc chia sẻ ngo&agrave;i &yacute; muốn. VNPT/C&ocirc;ng ty con của VNPT cam kết sẽ bảo mật một c&aacute;ch tối đa Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng. Một số hậu quả, thiệt hại kh&ocirc;ng mong muốn c&oacute; thể xảy ra bao gồm:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Lỗi phần cứng, phần mềm trong qu&aacute; tr&igrave;nh xử l&yacute; Dữ liệu c&aacute; nh&acirc;n g&acirc;y ảnh hưởng kh&ocirc;ng mong muốn (lỗi, hỏng, mất) Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Lỗ hổng bảo mật nằm ngo&agrave;i khả năng kiểm so&aacute;t của VNPT/C&ocirc;ng ty con của VNPT, hệ thống bị hacker tấn c&ocirc;ng g&acirc;y lộ lọt Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">c) Kh&aacute;ch h&agrave;ng tự l&agrave;m lộ lọt Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng do: bất cẩn hoặc bị lừa đảo; truy cập c&aacute;c website/tải c&aacute;c ứng dụng c&oacute; chứa phần mềm độc hại; tự &yacute; chia sẻ th&ocirc;ng tin với người kh&aacute;c.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. VNPT/C&ocirc;ng ty con của VNPT khuyến c&aacute;o Kh&aacute;ch h&agrave;ng thực hiện nghi&ecirc;m ngặt c&aacute;c tr&aacute;ch nhiệm bảo vệ Dữ liệu c&aacute; nh&acirc;n theo quy định tại Điều 8 Ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; theo quy định của ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Trong trường hợp xảy ra lỗi phần cứng, phần mềm trong qu&aacute; tr&igrave;nh xử l&yacute; Dữ liệu c&aacute; nh&acirc;n theo quy định tại Điểm a Khoản 1 Điều n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm bồi thường thiệt hại cho Kh&aacute;ch h&agrave;ng theo quy định tại Hợp đồng, Điều khoản chung v&agrave; ph&aacute;p luật.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Trong trường hợp xảy ra lỗi phần cứng, phần mềm trong qu&aacute; tr&igrave;nh xử l&yacute; Dữ liệu c&aacute; nh&acirc;n theo quy định tại Điểm a Khoản 1 Điều n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm bồi thường thiệt hại cho Kh&aacute;ch h&agrave;ng theo quy định tại Hợp đồng, Điều khoản chung v&agrave; ph&aacute;p luật. Trong trường hợp m&aacute;y chủ lưu trữ dữ liệu bị hacker tấn c&ocirc;ng dẫn đến mất m&aacute;t Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng hoặc Kh&aacute;ch h&agrave;ng tự l&agrave;m lột lọt Dữ liệu c&aacute; nh&acirc;n theo quy định tại Điểm b v&agrave; c Khoản 1 Điều n&agrave;y, VNPT/C&ocirc;ng ty con của VNPT c&oacute; tr&aacute;ch nhiệm sau (i) th&ocirc;ng b&aacute;o vụ việc cho cơ quan c&oacute; thẩm quyền để điều tra xử l&yacute; kịp thời v&agrave; th&ocirc;ng b&aacute;o cho Kh&aacute;ch h&agrave;ng được biết; (ii) Phối hợp với cơ quan c&oacute; thẩm quyền để giải quyết bảo đảm quyền lợi hợp ph&aacute;p cho kh&aacute;ch h&agrave;ng; (iii) Bồi thường thiệt hại cho kh&aacute;ch h&agrave;ng trong trường hợp c&oacute; lỗi của VNPT/C&ocirc;ng ty con của VNPT dẫn tới việc hacker tấn c&ocirc;ng hoặc lộ lọt Dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 12. Quảng c&aacute;o tr&ecirc;n internet v&agrave; b&ecirc;n thứ ba</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">C&aacute;c website/ứng dụng của VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể bao gồm quảng c&aacute;o của b&ecirc;n thứ ba v&agrave; li&ecirc;n kết tới c&aacute;c website/ứng dụng kh&aacute;c. C&aacute;c đối t&aacute;c quảng c&aacute;o b&ecirc;n thứ ba c&oacute; thể thu thập th&ocirc;ng tin về Kh&aacute;ch h&agrave;ng khi Kh&aacute;ch h&agrave;ng tương t&aacute;c với nội dung, quảng c&aacute;o hoặc dịch vụ của họ. Mọi quyền truy cập v&agrave; sử dụng c&aacute;c li&ecirc;n kết hoặc trang website của b&ecirc;n thứ ba kh&ocirc;ng bị điều chỉnh bởi Ch&iacute;nh s&aacute;ch n&agrave;y, m&agrave; thay v&agrave;o đ&oacute; được điều chỉnh bởi Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư của c&aacute;c b&ecirc;n thứ ba đ&oacute;.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 13. Xử l&yacute; Dữ liệu c&aacute; nh&acirc;n kh&ocirc;ng cần sự đồng &yacute; của chủ thể dữ liệu</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">VNPT/C&ocirc;ng ty con của VNPT c&oacute; thể tiến h&agrave;nh xử l&yacute; Dữ liệu c&aacute; nh&acirc;n m&agrave; kh&ocirc;ng cần sự đồng &yacute; của chủ thể dữ liệu trong c&aacute;c trường hợp sau:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Trong trường hợp khẩn cấp, cần xử l&yacute; ngay Dữ liệu c&aacute; nh&acirc;n c&oacute; li&ecirc;n quan để bảo vệ t&iacute;nh mạng, sức khỏe của chủ thể dữ liệu hoặc người kh&aacute;c;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Việc c&ocirc;ng khai Dữ liệu c&aacute; nh&acirc;n theo quy định của luật;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Việc xử l&yacute; dữ liệu của cơ quan nh&agrave; nước c&oacute; thẩm quyền trong trường hợp t&igrave;nh trạng khẩn cấp về quốc ph&ograve;ng, an ninh quốc gia, trật tự an to&agrave;n x&atilde; hội, thảm họa lớn, dịch bệnh nguy hiểm; khi c&oacute; nguy cơ đe dọa an ninh, quốc ph&ograve;ng nhưng chưa đến mức ban bố t&igrave;nh trạng khẩn cấp; ph&ograve;ng, chống bạo loạn, khủng bố, ph&ograve;ng, chống tội phạm v&agrave; vi phạm ph&aacute;p luật theo quy định của luật;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">4. Để thực hiện nghĩa vụ theo hợp đồng của chủ thể dữ liệu với VNPT/C&ocirc;ng ty con của VNPT theo quy định của luật;</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">5. Phục vụ hoạt động của cơ quan nh&agrave; nước đ&atilde; được quy định theo luật chuy&ecirc;n ng&agrave;nh.</span></p>
      <h4 class="indent">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Điều 14. Th&ocirc;ng tin li&ecirc;n lạc</span>
      </h4>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Trường hợp Kh&aacute;ch h&agrave;ng c&oacute; bất kỳ c&acirc;u hỏi n&agrave;o về Ch&iacute;nh s&aacute;ch n&agrave;y hoặc muốn thực hiện c&aacute;c quyền của Kh&aacute;ch h&agrave;ng li&ecirc;n quan tới Dữ liệu c&aacute; nh&acirc;n, vui l&ograve;ng li&ecirc;n hệ với VNPT/C&ocirc;ng ty con của VNPT theo c&aacute;c phương thức v&agrave; th&ocirc;ng tin dưới đ&acirc;y:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">1. Li&ecirc;n hệ tới tổng đ&agrave;i theo th&ocirc;ng tin tại c&aacute;c website/ứng dụng ch&iacute;nh thức của VNPT/C&ocirc;ng ty con của VNPT tại từng thời điểm.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">2. Gửi c&ocirc;ng văn tới c&aacute;c địa chỉ sau đ&acirc;y:</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">a) Tổng C&ocirc;ng ty Dịch vụ Viễn th&ocirc;ng (VNPT VinaPhone): tại To&agrave; nh&agrave; VinaPhone, đường Xu&acirc;n Tảo, Phường Xu&acirc;n La, Quận T&acirc;y Hồ, H&agrave; Nội.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">b) Tổng C&ocirc;ng ty Truyền th&ocirc;ng (VNPT Media): tại T&ograve;a nh&agrave; VNPT, 57 Huỳnh Th&uacute;c Kh&aacute;ng, Phường L&aacute;ng Hạ, Quận Đống Đa, H&agrave; Nội.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">c) C&ocirc;ng ty C&ocirc;ng nghệ th&ocirc;ng tin VNPT (VNPT IT): tại T&ograve;a nh&agrave; VNPT, 57 Huỳnh Th&uacute;c Kh&aacute;ng, Phường L&aacute;ng Hạ, Quận Đống Đa, H&agrave; Nội.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">3. Li&ecirc;n hệ trực tiếp tại c&aacute;c điểm giao dịch của VNPT/C&ocirc;ng ty con của VNPT tr&ecirc;n phạm vi to&agrave;n quốc.</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">4. C&aacute;c c&aacute;ch thức li&ecirc;n hệ kh&aacute;c như Livechat (phần mềm tr&ograve; chuyện trực tuyến), li&ecirc;n hệ qua fanpage (trang th&ocirc;ng tin điện tử) ch&iacute;nh thức của VNPT/C&ocirc;ng ty con của VNPT, email chăm s&oacute;c Kh&aacute;ch h&agrave;ng được cung cấp cho Kh&aacute;ch h&agrave;ng tại mọi thời điểm./</span></p>
      <p><span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;"><strong>(*) Theo d&otilde;i nội dung dưới đ&acirc;y để xem hoặc sửa đổi sự x&aacute;c nhận của Qu&yacute; kh&aacute;ch đối với Mục đ&iacute;ch xử l&yacute; dữ liệu c&aacute; nh&acirc;n</strong></span></p>
      <p dir="rtl">
        <span style="font-family: 'times new roman', times; color: #000000; font-size: 12pt;">Kh&aacute;ch h&agrave;ng vui l&ograve;ng t&iacute;ch chọn c&aacute;c Mục Đ&iacute;ch m&agrave; Kh&aacute;ch h&agrave;ng cho ph&eacute;p VNPT/C&ocirc;ng ty con của VNPT thực hiện Xử l&yacute; Dữ liệu c&aacute; nh&acirc;n tại cột &ldquo;Chấp thuận của Kh&aacute;ch h&agrave;ng&rdquo;. C&aacute;c Mục Đ&iacute;ch Kh&aacute;ch h&agrave;ng kh&ocirc;ng chấp thuận, Kh&aacute;ch h&agrave;ng vui l&ograve;ng bỏ trống.</span>
      </p>

      <b-row>
        <b-col style=" font-family: 'Times New Roman';">
          <vue-good-table
            :columns="columns"
            :rows="dataPolicy"
            :line-numbers="true"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <template>
                <span v-if="props.column.field === 'vote'">
                  <b-form-checkbox
                    v-model="selected"
                    :value="props.row.id"
                    disabled
                  />
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br>
          <p style="text-align: center;">
            <span style=" text-align: center; font-family: 'times new roman', times; color: #000000; font-size: 12pt;">VNPT/Công ty con của VNPT không thực hiện hoạt động mua bán Dữ liệu cá nhân dưới bất kỳ hình thức nào.</span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'

export default {
  name: 'Policy',
  components: {
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      columns: [
        {
          label: 'Mục Đích Xử lý Dữ liệu cá nhân',
          field: 'policy',
          thClass: 'text-center',
        },
        {
          label: 'Chấp thuận của Khách hàng',
          field: 'vote',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      dataPolicy: [
        {
          id: 1,
          policy: 'Xác minh tính chính xác, đầy đủ của các thông tin được Khách hàng cung cấp; xác định hoặc xác thực danh tính của Khách hàng và thực hiện quy trình xác thực Khách hàng;',
        },
        {
          id: 2,
          policy: 'Thẩm định hồ sơ và khả năng đủ điều kiện của Khách hàng đối với việc sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT. VNPT/Công ty con của VNPT có thể sử dụng các phương pháp chấm điểm, gán ngưỡng cước nóng, kiểm tra lịch sử Khách hàng sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT để đánh giá và quản trị rủi ro tín dụng, đảm bảo khả năng thanh toán đối với các nghĩa vụ thanh toán và các nghĩa vụ khác có liên quan trong suốt quá trình cung cấp sản phẩm, dịch vụ của VNPT/Công ty con của VNPT cho Khách hàng;',
        },
        {
          id: 3,
          policy: 'Quản lý và đánh giá các hoạt động kinh doanh bao gồm thiết kế, cải tiến và nâng cao chất lượng các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT hoặc thực hiện các hoạt động truyền thông tiếp thị; Thực hiện nghiên cứu thị trường, khảo sát và phân tích dữ liệu liên quan đến sản phẩm, dịch vụ của VNPT/Công ty con của VNPT; nghiên cứu, phát triển các sản phẩm, dịch vụ mới, mô hình cung cấp mới đáp ứng nhu cầu của Khách hàng;',
        },
        {
          id: 4,
          policy: 'Cung cấp dịch vụ cho Khách hàng, liên hệ với Khách hàng nhằm tư vấn, trao đổi thông tin, giải quyết yêu cầu, khiếu nại, giao các hóa đơn, các sao kê, các báo cáo hoặc các tài liệu khác liên quan tới sản phẩm, dịch vụ của VNPT/Công ty con của VNPT thông qua các kênh khác nhau (ví dụ: email, chat) và để trả lời yêu cầu của Khách hàng. Liên hệ với Khách hàng (hoặc các bên do Khách hàng chỉ định hoặc yêu cầu) để thông báo cho Khách hàng về thông tin liên quan đến việc sử dụng sản phẩm, dịch vụ của VNPT/Công ty con của VNPT;',
        },
        {
          id: 5,
          policy: 'Quảng cáo, tiếp thị dựa trên sở thích, thói quen sử dụng dịch vụ của Khách hàng: VNPT/Công ty con của VNPT có thể sử dụng Dữ liệu cá nhân để quảng cáo, tiếp thị với Khách hàng về các sản phẩm, dịch vụ của VNPT/Công ty con của VNPT, chương trình khuyến mại, nghiên cứu, khảo sát, tin tức, thông tin cập nhật, các sự kiện, cuộc thi có thưởng, trao các phần thưởng có liên quan, các quảng cáo và nội dung có liên quan về sản phẩm, dịch vụ của VNPT/Công ty con của VNPT hoặc của các đối tác hợp tác với VNPT/Công ty con của VNPT.\n'
              + '\n'
              + 'Trường hợp Khách hàng không muốn tiếp tục nhận email, tin nhắn và/hoặc bản tin định kỳ với mục đích quảng cáo, tiếp thị của VNPT/Công ty con của VNPT với tần suất tùy thuộc vào Chính sách của VNPT/Công ty con của VNPT theo từng thời kỳ và phù hợp với quy định pháp luật, Khách hàng có thể từ chối theo cách thức đã được VNPT/Công ty con của VNPT hướng dẫn trên các kênh, phương tiện như tin nhắn SMS, cuộc gọi, dấu tích trên website/wapsite/ứng dụng hoặc liên hệ với tổng đài chăm sóc Khách hàng của VNPT/Công ty con của VNPT;',
        },
        {
          id: 6,
          policy: 'Lập các báo cáo tài chính, báo cáo hoạt động, hồ sơ phục vụ công tác mời thầu hoặc tham dự thầu hoặc các loại báo cáo liên quan khác mà pháp luật quy định;',
        },
        {
          id: 7,
          policy: 'Tuân thủ các nghĩa vụ pháp lý theo quy định của pháp luật;',
        },
        {
          id: 8,
          policy: 'Ngăn chặn gian lận hoặc giảm thiểu mối đe doạ đối với tính mạng, sức khỏe của người khác và lợi ích công cộng: VNPT/Công ty con của VNPT có thể sử dụng thông tin cá nhân của Khách hàng để ngăn chặn và phát hiện gian lận, lạm dụng nhằm bảo vệ Khách hàng, VNPT/Công ty con của VNPT và các chủ thể liên quan;',
        },
        {
          id: 9,
          policy: 'Quản trị nội bộ.',
        },
      ],
      selected: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    }
  },
}
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
