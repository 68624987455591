<template>
  <b-tabs
      vertical
      content-class="col-12 col-md-10 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-2 col-12"
      nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
        />
        <span class="font-weight-bold">Điều khoản sử dụng dịch vụ qua ứng dụng/website {{appName}}</span>
      </template>
      <b-card>
        <b-row>
          <b-col
              cols="12"
              class="mb-2"
          >
            <TermsOfUse
                :app-name="appName"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
        />
        <span class="font-weight-bold">Chính sách bảo vệ dữ liệu cá nhân</span>
      </template>

      <b-card>
        <b-row>
          <b-col
              cols="12"
              class="mb-2"
          >
            <Policy />
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BContainer,
} from 'bootstrap-vue'
import TermsOfUse from "@/views/privacy-policy/TermsOfUse.vue";
import Policy from "@/views/privacy-policy/Policy.vue";

export default {
  name: 'PrivacyPolicy',
  components: {
    Policy,
    TermsOfUse,
    BContainer,
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
  },
  data() {
    return {
      appName: 'BNC - Bách Nghệ',
    }
  }
}
</script>
<style scoped lang="scss">

</style>
